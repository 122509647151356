.App {
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: white;
  height: 150px;
  padding: 20px;
  color: #222;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.closeButton {
  position: absolute;
  right: 25px;
  top: 25px;
  display: block;
  width: 100px;
  height: 50px;
  background: black;
  color: white;
  z-index: 1001;
}

.debugIframe {
  position: absolute;
  top: 25px;
  left: 10px;
  width: calc(100vw - 150px);
  background: black;
  color: white;
  z-index: 1001;
  display: none;
}

.myframe,
#webview,
.video {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.myframewithbackground {
  background: rgba(255, 255, 255, 0.9);
}

.video {
  background: black;
  z-index: 1004;
}

.video video {
  width: 100%;
  height: 100%;
}

html,
body,
#root {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

header.site-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgb(15,15,15);
  z-index: 1005;

  height: 50px;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}

header.site-header div.close-button a {
  display: block;
  height: 100%;
}

header.site-header div.close-text {
  color: white;
  position: absolute;
  top: 0;
  right: 45px;
  line-height: 48px;
  cursor: pointer;
  /* z-index: 3000; */
}

header.site-header div.close-button svg {
  width: 44px;
  height: 44px;
  color: white;
  fill: white;
  padding: 12px;
  cursor: pointer;
}

.loader {
  background-color: rgb(25, 25, 25);
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1006;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
