@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Light.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Light.woff') format('woff');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Thin.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Thin.woff') format('woff');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Light.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Regular.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Medium.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Bold.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Bold.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Light-Oblique.woff2')
      format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Light-Oblique.woff')
      format('woff');
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Thin-Oblique.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Thin-Oblique.woff') format('woff');
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Light-Oblique.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Light-Oblique.woff') format('woff');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Regular-Oblique.woff2')
      format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Regular-Oblique.woff') format('woff');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Medium-Oblique.woff2')
      format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Medium-Oblique.woff') format('woff');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Bold-Oblique.woff2') format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Bold-Oblique.woff') format('woff');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Walsheim';
  src: local('☺︎'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Bold-Oblique.woff2')
      format('woff2'),
    url('../../../Assets/Fonts/GT-Walsheim-Ultra-Bold-Oblique.woff')
      format('woff');
  font-style: italic;
  font-weight: 800;
}
